import {graphql} from "gatsby";
import link from "./linkResolver"
import {LinkProps} from "../../components/Link/Link";

export type LinkTypes = "Link.document" | "Link.web" | "Link.image"

interface PrismicLink {
    _meta?: {
        type: string
        uid: string
    }
    url?: string
    _linkType: LinkTypes
    type?: string
    uid?: string
    slug_prefix?: string | null
}

export default {
    url(link, linkResolver) {
        if (link && link._linkType && link._linkType === 'Link.document') {
            return linkResolver ? linkResolver(link) : '';
        } else if (link && link.url) {
            return link.url;
        } else {
            return null;
        }
    }
}


export function linkResolver(doc: PrismicLink) {
    let prefix = ''
    if (doc?.slug_prefix) {
        prefix = doc.slug_prefix.replace(/^\/+|\/+$/g, '') + '/'
    }
    
    // For some reason /preview is returning a different data structure from the rest api.
    if(doc?.data?.slug_prefix){
        prefix = doc.data.slug_prefix.replace(/^\/+|\/+$/g, '') + '/'
    }
    
    if (!!doc._meta && doc._meta.type === "page") {
        if(doc._meta.uid === 'home'){
            return '/';
        }
        
        return `/${prefix}${doc._meta.uid}`
    }

    // URL for a category type
    if (!!doc.uid && !!doc.type && doc.type === "page") {
        return `/${prefix}${doc.uid}`
    }

    // Backup for all other types
    // return "/"
}

export interface PrismicLinkProps {
    button_url: any
    button_text: string
    linkType?: string
}

export function prismicLinkMaker(linkProps: PrismicLinkProps): LinkProps | null {
    const {button_url, button_text} = linkProps;

    if (button_text && button_url) {
        return {
            linkType: button_url._linkType,
            to: link.url(button_url, linkResolver),
            children: button_text
        }
    }

    return null;
}

export const query = graphql`
    fragment Linkable on PRISMIC__Linkable{
        _linkType

        ... on PRISMIC__ExternalLink {
            url
        }
        ...on PRISMIC__ImageLink{
            url
        }
        ...on PRISMIC__Document{
            _meta{
                type
                uid
            }
        }
        ...on PRISMIC__FileLink{
            url
        }
        ... on PRISMIC_Page{
            _meta{
                id
                uid
            }
            slug_prefix
        }
    }
`