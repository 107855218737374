module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#798C4B","theme_color":"#798C4B","display":"minimal-ui","icon":"src/images/favicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5K5NDVH","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{}}},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"accutwist","accessToken":"MC5Yc1U2MlJFQUFDVUEtNVFB.S--_ve-_vRRvNyhtSwbvv71YPO-_vX1zXQ7vv71hHnrvv73vv73vv70SB--_vXDvv73vv71c","path":"/preview","previews":true,"sharpKeys":[{},"profilepic"],"extraPageFields":"slug_prefix","pages":[{"type":"Page","match":"/","path":"/page","component":"/opt/build/repo/src/prismic/templates/page.js"},{"type":"Page","match":"/:uid","path":"/page","component":"/opt/build/repo/src/prismic/templates/page.js"},{"type":"Page","match":"/industries/:uid","path":"/page","component":"/opt/build/repo/src/prismic/templates/page.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
